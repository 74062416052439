import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import loading from "../../images/loading.gif";

import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "You may call the phone number +91-9846557534 or submit your details below.",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const nameEl = React.useRef(null);
  const emailEl = React.useRef(null);
  const phoneEl = React.useRef(null);
  const messageEl = React.useRef(null);

  const recaptchaRef = React.createRef();
  const responseMessage = React.createRef();
  const submitBtn = React.createRef();
  const loaderRow = React.createRef(); 

  const handleSubmit = e => {

    e.preventDefault();

    const token = recaptchaRef.current.getValue();

    const data = {
      "name": nameEl.current.value,
      "email": emailEl.current.value,
      "phone": phoneEl.current.value,
      "message": messageEl.current.value,
      "token": token    
    };

    loaderRow.current.style.visibility = "visible";
    submitBtn.current.disabled = true;

    //console.log(data);
    
    axios({
      url: "https://2248sjf3r9.execute-api.us-west-2.amazonaws.com/live?token="+token,
      method: "post",
      data:  data        
    }).then(function(response){

      loaderRow.current.style.visibility = "hidden"
      submitBtn.current.disabled = false;

      //console.log(response)
      if (response.data.SendEmailResponse){
        //this.responseMessage.current.innerHTML = response.data.success
        responseMessage.current.innerHTML = "Thanks for your interest with us.<br /> We will get back to you shortly."; 
        //resetForm()
      }else{
        responseMessage.current.innerHTML = "Error occured. Please try again later !!!"
      }

    })

    e.target.reset();
    recaptchaRef.current.reset();

  }

  return (
    <Container id="contact">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            
            <div id="responseMessage" ref={responseMessage} style={{ color: 'red', paddingTop: '20px' }}></div>

            <Form onSubmit={handleSubmit} >

              <Input type="text" 
              name="name" 
              ref={nameEl} 
              placeholder="Full Name *" 
              required />

              <Input type="email" 
              name="email" 
              ref={emailEl} 
              placeholder="Your Email Address *" 
              required />

              <Input type="text" 
              name="phone" 
              placeholder="Phone *" 
              ref={phoneEl} 
              required 
              />

              <Textarea name="message" 
              placeholder="Your Message Here"
              ref={messageEl} 
              required />

              <ReCAPTCHA 
                style={{ marginTop: '20px' }}
                ref={recaptchaRef} 
                sitekey="6Lc8uosaAAAAAG-LX-HSHTrwfobyFsoPUkmtN_Hh" 
                onExpired={() => {
                  recaptchaRef.current.reset(); 
                }} />

              <SubmitButton type="submit" ref={submitBtn} >{submitButtonText}</SubmitButton>

              <div className="row" ref={loaderRow} style={{ marginTop: "20px", visibility: "hidden"}}>
                <div className="col-md-3"><img  style={{ marginLeft: "25%" }} src={loading} alt="Pavanathara" title="Pavanathara" /></div>
                <div className="col-md-9">Please wait while we are processing your request...</div> 
              </div>

            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
